import * as React from 'react';
import { ResizeObserver as PolyfillResizeObserver } from '@juggle/resize-observer';

export default function useResizeObserver(element: HTMLElement, onResize: ResizeObserverCallback) {
  React.useEffect(() => {
    if (element && onResize) {
      const ResizeObserver = window.ResizeObserver || PolyfillResizeObserver;
      const observer = new ResizeObserver(onResize);
      observer.observe(element);

      return () => {
        observer.disconnect();
      };
    }
    return () => {};
  }, [element, onResize]);
}
