export const carouselGridSize = {
  KOLO: '97.125',
  TWYFORD: '97.125',
  NORD: '97.125',
  GDDS: '85.125',
};

export const gridSize = {
  KOLO: 'gddsFullGrid',
  TWYFORD: 'gddsFullGrid',
  NORD: 'gddsFullGrid',
  GDDS: 'full',
};

export const tileSizes = {
  quadratic: 's',
  horizontal_1_1: 's',
  horizontal_2_1: 'm',
  event_tile: 's',
};
