import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import getEndpoint from 'utils/endpoints';

export default function useJobCount(): { vacancies: number; countries: number } | undefined {
  const state = useSelector((s) => s);
  const { data } = useQuery({
    queryKey: ['jobCount'],
    queryFn: async () => {
      const endpoint = getEndpoint('jobCount', state);
      const resp = await fetch(endpoint);
      if (resp.ok) {
        return await resp.json();
      }
      return undefined;
    },
  });
  return data;
}
