import isEmpty from 'lodash.isempty';
import { ExpressiveTile, Icon } from '@geberit/gdds';

// components
import { CmsLink } from 'components/Link/CmsLink';
import { Headline, Formats, getFontWeight } from 'components/ContentElements/Headline';
import { Loader } from 'components/Loader/Loader';
import { InlineEdit } from 'components/ContentCreator/InlineEdit';

// utils
import { clickTileLink } from 'components/ContentElements/Teaser/trackingActions';
import useJobCount from 'utils/hooks/use-jobcount';
import { useTracking } from 'utils/hooks/useTracking';
import { useThemeName } from 'utils/hooks/use-theme';

// styles
import styles from './tiles.module.scss';
import { useIsMobile } from 'components/App/SizeProvider';

interface JobTilesProps {
  title: string;
  subtitle: string;
  emphasize: 'title' | 'subtitle';
  label1?: string;
  label2?: string;
  link?: Link;
}

export function JobTiles({
  title,
  subtitle,
  emphasize,
  link,
  label1,
  label2,
  ...props
}: Readonly<JobTilesProps>) {
  const isMobile = useIsMobile();
  const jobCount = useJobCount();
  const track = useTracking();
  const themeName = useThemeName();

  const trackingClick = (e) => {
    track.trackEvent(clickTileLink(window.location.href, e.currentTarget.href));
  };
  return (
    <ExpressiveTile
      {...props}
      link={link}
      title={title}
      subTitle={subtitle}
      emphasize={emphasize}
      backgroundType="light"
      type="headlineWithShortTeaser"
    >
      <div className={styles.jobtile}>
        <Headline
          format={Formats.h3}
          title={title}
          subtitle={subtitle}
          tag={Formats.h2}
          titleFontWeight={getFontWeight(emphasize === 'title', themeName, Formats.h3)}
          subtitleFontWeight={getFontWeight(emphasize === 'subtitle', themeName, Formats.h3)}
          className={styles.headline}
          titlePreviewId="#st_title"
          subtitlePreviewId="#st_subtitle"
        />
        {jobCount ? (
          <>
            <div className={styles.vacancies}>
              <Icon symbol="Profile" width={isMobile ? '1.5rem' : '2rem'} />
              <div className={styles.vacanciesContent}>
                <InlineEdit previewId={`#st_label1`}>
                  <p>{label1}</p>
                </InlineEdit>
                <span className={styles.vacanciesTypo}>{jobCount.vacancies}</span>
              </div>
            </div>
            <div className={styles.countries}>
              <Icon symbol="Globe" width={isMobile ? '1.5rem' : '2rem'} />
              <div className={styles.countriesContent}>
                <InlineEdit previewId={`#st_label2`}>
                  <p>{label2}</p>
                </InlineEdit>
                <span className={styles.countriesTypo}>{jobCount.countries}</span>
              </div>
            </div>
          </>
        ) : (
          <Loader />
        )}
        {!isEmpty(link) && (
          <CmsLink
            link={link}
            alignByContent={link.showArrow ? 'left' : undefined}
            tracking={trackingClick}
          />
        )}
      </div>
    </ExpressiveTile>
  );
}
