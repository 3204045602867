import isEmpty from 'lodash.isempty';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';

// types
import { EventsByYearRaw, EventsSorted } from './types';

export function sortEventsByYear(events: EventsByYearRaw): EventsSorted {
  let sortedEvents: EventsSorted = [];
  const sortedYears = Object.keys(events).sort((a, b) => parseInt(a) - parseInt(b));

  sortedYears.forEach((year) => {
    if (!isEmpty(events[year])) {
      sortedEvents = [...sortedEvents, { year: parseInt(year), events: events[year] }];
    }
  });

  return sortedEvents;
}

export function getSortedEvents(event) {
  const currentDate = new Date();
  const today = currentDate.setHours(0, 0, 0, 0);
  const upcoming = event.events.filter((item) =>
    isAfter(new Date(item.startDate).setHours(1, 0, 0, 0), today),
  );
  const past = event.events.filter((item) =>
    isBefore(new Date(item.startDate).setHours(0, 0, 0, 0), today),
  );

  return [upcoming, past];
}
