// utils
import { trackTemplate } from '../../../utils/tracking';

/**
 * Tile links
 * @param string text
 */
export function clickTileLink(pageurl, url) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Tiles',
      action: pageurl,
      label: !url.startsWith('http') ? `${window.location.origin}${url}` : url,
      value: '0',
    },
  });
}

/**
 * Button Click Locator Form
 * @param postalCode
 */
export function locatorTileSubmit(postalCode) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Search',
      action: 'Locator-Tile-Search',
      label: `[${postalCode}]`,
    },
  });
}
