import { isEmpty } from 'lodash';
import key from 'weak-key';
import { Container, Col, Row, Tiles } from '@geberit/gdds';

// styles
import styles from './tiles.module.scss';

// types
import type { ExpressiveTilesPayload } from 'components/ContentElements/content-elements-payload';

// components
import ExpressiveTileLoader from './expressive-tile-loader';

// utils
import { buildSize, gridSizes } from 'utils/gridSize';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useIsPreview } from 'utils/hooks/useIsPreview';

export function ExpressiveTiles({ items, background }: Readonly<ExpressiveTilesPayload>) {
  const isPreview = useIsPreview();
  if (isEmpty(items)) return null;

  return (
    <div className={classNameBuilder(styles[background], styles.expressiveWrapper)}>
      <Container maxContentWidth={buildSize(gridSizes.full)} className={styles.grid}>
        <Row>
          <Col>
            <Tiles
              previewId="#st_tiles"
              previewIdExtractor={(_, index) => `#${index}`}
              type="expressive"
              isPreview={isPreview}
            >
              {items.map((item, index) => (
                <ExpressiveTileLoader key={key(item)} index={index} {...item} />
              ))}
            </Tiles>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
