import axios from 'axios';
import { Dispatch } from 'redux';

// utils
import { sortEventsByYear } from './utils';
import { currentLanguageSelector } from 'utils/selectors/languageSelectors';
import { getEndpoint } from 'utils/endpoints';
import { EventsResponse } from 'services/events';

/**
 * @param {String} endpoint – endpoint
 *
 * @returns {Object} action
 */
function eventsRequest() {
  return {
    type: 'EVENTS_REQUEST_PENDING' as const,
  };
}

/**
 * @param {Object} response – response
 *
 * @returns {Object} action
 */
function eventsRequestFulfilled(language: string, events: EventsResponse['data']) {
  return {
    type: 'EVENTS_REQUEST_FULFILLED' as const,
    language,
    events,
  };
}

/**
 * @param {Object} response – response
 *
 * @returns {Object} action
 */
function mostcurrentEventsRequestFulfilled(language: string, events: EventsResponse['data']) {
  return {
    type: 'EVENTS_MOSTCURRENT_REQUEST_FULFILLED' as const,
    language,
    events,
  };
}

/**
 * @param {Object} error – error
 *
 * @returns {Object} action
 */
function eventsRequestRejected(error: RequestError) {
  return {
    type: 'EVENTS_REQUEST_REJECTED' as const,
    error,
  };
}

export function fetchAllEvents() {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const language = currentLanguageSelector(state);
    const endpoint = `${getEndpoint('events', state)}/?language=${language}`;

    dispatch(eventsRequest());

    try {
      const response = await axios.get(endpoint);

      const sortedEvents = response.data.reduce((prev, cur) => {
        const year = new Date(cur.startDate).getFullYear().toString();
        const index = prev.findIndex((item) => item.year === year);

        if (index !== -1) {
          prev[index] = { year, events: [...prev[index].events, cur] };

          return prev;
        }

        return [...prev, { year, events: [cur] }];
      }, []);

      dispatch(eventsRequestFulfilled(language, sortedEvents));
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        dispatch(eventsRequestRejected(error.response));
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        dispatch(eventsRequestRejected(error.request));
      } else {
        // Something happened in setting up the request that triggered an Error
        dispatch(eventsRequestRejected(error.message));
      }
    }
  };
}

/**
 * @param {String} language – the language in which to get the navigation
 *
 * @returns {Promise} data
 */
export function fetchEvents(years: number[]) {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const language = currentLanguageSelector(state);
    const endpoint = `${getEndpoint('events', state)}/by/years?years=${years.join(
      ',',
    )}&language=${language}`;

    dispatch(eventsRequest());

    try {
      const response = await axios.get(endpoint);

      const sortedEvents = sortEventsByYear(response.data);

      dispatch(eventsRequestFulfilled(language, sortedEvents));
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        dispatch(eventsRequestRejected(error.response));
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        dispatch(eventsRequestRejected(error.request));
      } else {
        // Something happened in setting up the request that triggered an Error
        dispatch(eventsRequestRejected(error.message));
      }
    }
  };
}

/**
 * @param {String} language – the language in which to get the navigation
 *
 * @returns {Promise} data
 */
export function fetchMostCurrentEvents() {
  return async (dispatch: Dispatch, getState: () => AppState) =>
    (async () => {
      const state = getState();
      const language = currentLanguageSelector(state);
      const endpoint = `${getEndpoint('events', state)}/mostcurrent?language=${language}`;

      dispatch(eventsRequest());

      try {
        const response = await axios.get(endpoint);
        dispatch(mostcurrentEventsRequestFulfilled(language, response.data));
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          dispatch(eventsRequestRejected(error.response));
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          dispatch(eventsRequestRejected(error.request));
        } else {
          // Something happened in setting up the request that triggered an Error
          dispatch(eventsRequestRejected(error.message));
        }
        throw error;
      }
    })();
}

export type EventsAction =
  | ReturnType<typeof eventsRequest>
  | ReturnType<typeof eventsRequestFulfilled>
  | ReturnType<typeof mostcurrentEventsRequestFulfilled>
  | ReturnType<typeof eventsRequestRejected>;
